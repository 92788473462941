import {config, dom, library} from '@fortawesome/fontawesome-svg-core'
import {faAt, faBell, faBuilding, faCalendarAlt, faChartArea, faChartBar, faCheckCircle, faCircle, faCoins, faCreditCard, faDesktop, faEdit, faEuroSign, faExchangeAlt, faFileArrowDown, faFileInvoiceDollar, faHandHoldingHeart, faHashtag, faHourglassHalf, faIdCard, faIndustry, faMapMarkerAlt, faPaperPlane, faPause, faPercent, faPlay, faPlus, faRobot, faRotate, faShieldAlt, faSignOutAlt, faTimesCircle, faTrash, faUser, faUsers, faUserShield, faWallet} from '@fortawesome/free-solid-svg-icons'
import {faBitcoin, faCcMastercard, faCcVisa, faEthereum, faTelegram} from "@fortawesome/free-brands-svg-icons";

config.mutateApproach = 'sync';

library.add(
    faSignOutAlt,
    faUserShield,
    faDesktop,
    faUsers,
    faPlus,
    faEdit,
    faTrash,
    faHashtag,
    faIdCard,
    faAt,
    faTelegram,
    faExchangeAlt,
    faCoins,
    faChartBar,
    faChartArea,
    faPercent,
    faMapMarkerAlt,
    faBuilding,
    faRobot,
    faBell,
    faHourglassHalf,
    faHandHoldingHeart,
    faCheckCircle,
    faTimesCircle,
    faUser,
    faIndustry,
    faCreditCard,
    faCcMastercard,
    faCcVisa,
    faCircle,
    faWallet,
    faShieldAlt,
    faCalendarAlt,
    faBitcoin,
    faEthereum,
    faEuroSign,
    faFileInvoiceDollar,
    faPaperPlane,
    faRotate,
    faFileArrowDown,
    faPause,
    faPlay
);

dom.watch();
